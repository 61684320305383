import { useState, useEffect } from "react";
import { FaBell, FaCheckCircle, FaExclamationCircle, FaInfoCircle } from "react-icons/fa";

function Popup({ message, type = "info", onClose }) {
    const [progress, setProgress] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const duration = 5000; // Duration in milliseconds
  
    useEffect(() => {
      setIsVisible(true); // Show the popup when mounted
  
      const timer = setTimeout(() => {
        onClose(); // Close the Popup automatically after the duration
      }, duration);
  
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100; // Ensure it ends at 100%
          }
          return prev + (100 / (duration / 100)); // Increase progress
        });
      }, 100);
  
      return () => {
        clearTimeout(timer);
        clearInterval(interval); // Clear the interval on component unmount
        setIsVisible(false); // Reset visibility state when closing
      };
    }, [onClose]);
  
    const iconTypes = {
      success: <FaCheckCircle className="text-green-600" />,
      error: <FaExclamationCircle className="text-red-600" />,
      warning: <FaExclamationCircle className="text-yellow-600" />,
      info: <FaInfoCircle className="text-blue-600" />,
      notification: <FaBell className="text-gray-600" />,
    };
  
    const bgColors = {
      success: "bg-gradient-to-r from-green-400 to-green-600",
      error: "bg-gradient-to-r from-red-400 to-red-600",
      warning: "bg-gradient-to-r from-yellow-400 to-yellow-600",
      info: "bg-gradient-to-r from-blue-400 to-blue-600",
      notification: "bg-gradient-to-r from-gray-300 to-gray-500",
    };
  
    return (
      <div
        className={`fixed top-20 right-8 mx-auto flex items-center p-4 rounded-full overflow-hidden shadow-2xl border-l-4 ${bgColors[type]} transition-transform duration-300 z-50 transform ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'} ease-out`}
        style={{ opacity: isVisible ? 1 : 0 }} // Control opacity for fade effect
      >
        <div className="text-2xl">{iconTypes[type]}</div>
        <div className="flex-1 text-white font-semibold ml-2">{message}</div>
        <button
          onClick={onClose}
          className="ml-4 text-white hover:text-gray-200 focus:outline-none"
        >
            ✕
        </button>
  
        <div
          className={`absolute left-0 bottom-0 h-1 rounded bg-white transition-all duration-${duration} ease-linear`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    );
  }

  export default Popup;