import { useState } from "react";
import Popup from "./Popup";
import axios from "axios";

export const Contacts = () => {
  const [purpose, setPurpose] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUp, setPopUp] = useState({
    show: false,
    message: "",
    type: "",
    timeOut: null,
  });

  const validateContact = (contact) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const indiaPhoneRegex = /^(\+91)?[1-9]\d{9}$/; // Allows optional +91 country code

    // Check if the contact is an email
    if (emailRegex.test(contact)) {
      return { isValid: true, type: "email", formattedContact: contact };
    }

    // Remove leading 0 if present
    if (contact.startsWith("0")) {
      contact = contact.substring(1);
    }

    // Check if the contact is a valid Indian phone number
    if (indiaPhoneRegex.test(contact)) {
      // Remove +91 if present
      if (contact.startsWith("+91")) {
        contact = contact.substring(3);
      }

      // Check if the formatted number exceeds 10 digits
      if (contact.length > 10 || contact.length < 10) {
        return { isValid: false, type: "phone", formattedContact: contact };
      }

      return { isValid: true, type: "phone", formattedContact: contact };
    }

    // If neither, return invalid
    return { isValid: false, type: null, formattedContact: null };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if all fields are filled
    if (!purpose || !name || !contact || !message) {
      setPopUp({
        show: true,
        type: "error",
        message: "Please fill all fields",
        timeout: 3000,
      });
      return;
    }

    // Validate contact (email or phone number)
    const validationResult = validateContact(contact);

    if (!validationResult.isValid) {
      // dispatch(showPopup("Invalid email or mobile number", "error"));
      setPopUp({
        show: true,
        type: "error",
        message: "Invalid email or mobile number",
        timeout: 3000,
      });
      return;
    }

    setLoading(true);

    // Format the contact if valid (e.g., remove +91 or leading zero for phone numbers)
    const formattedContact = validationResult.formattedContact;
    try {
      // Send email via API
      const response = await axios.post(
        "https://email-backend-contact-677410853484.asia-southeast1.run.app/send-reealtech-email",
        {
          purpose,
          name,
          contact: formattedContact,
          message,
        }
      );
      setPopUp({
        show: true,
        type: "success",
        message: "Message sent successfully!",
        timeout: 3000,
      });
      // Show success popup
      //   dispatch(showPopup("Message sent successfully!", "success"));

      // Reset form fields
    } catch (error) {
      console.error("Error sending email:", error);
      setPopUp({
        show: true,
        type: "error",
        message: "Error submitting your contact. Please try again.",
        timeout: 3000,
      });
    } finally {
      setLoading(false);
    }

    setPurpose("");
    setName("");
    setContact("");
    setMessage("");
    // Proceed to send data to the server
    // alert(`Data sent to the server with contact: ${formattedContact}`);
  };

  return (
    <div
      className={`bg-white  text-[16px] sm:text-[18px] text-justify w-full h-full justify-center items-center rounded-lg overflow-y-scroll`}
    >
      <div className="w-full bg-gradient-to-r from-purple-500 via-indigo-500 to-sky-500 flex flex-col items-center justify-center px-2 sm:px-4 lg:px-6">
        <div className="text-center mb-5 mt-5">
          <h1 className="text-3xl md:text-4xl font-extrabold text-white tracking-wide">
            Get in Touch
          </h1>
          <p className="text-indigo-100 mt-2 text-sm md:text-base">
            Let us know how we can help or learn more about our products.
          </p>
        </div>
        {/* Form Section */}
        <form className="w-full max-w-lg mb-5 bg-white p-6 md:p-8 rounded-xl shadow-2xl space-y-6">
          {/* Purpose */}
          <div className="flex flex-col text-[14px]">
            <label
              htmlFor="purpose"
              className="text-gray-700 font-semibold text-sm md:text-base"
            >
              Purpose
            </label>
            <select
              id="purpose"
              className="mt-2 px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400"
              onChange={(e) => setPurpose(e.target.value)}
              value={purpose || ""}
              required
            >
              <option value="" disabled selected>
                Select a purpose
              </option>
              <option value="support">Support</option>
              <option value="product_inquiry">Product Inquiry</option>
              <option value="feedback">Feedback</option>
              <option value="other">Other</option>
            </select>
          </div>
          {/* Name */}
          <div className="flex flex-col">
            <label
              htmlFor="name"
              className="text-gray-700 font-semibold text-sm md:text-base"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              className="mt-2 px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400"
              placeholder="Enter your name"
              onChange={(e) => setName(e.target.value)}
              value={name || ""}
              required
            />
          </div>
          {/* Contact */}
          <div className="flex flex-col">
            <label
              htmlFor="contact"
              className="text-gray-700 font-semibold text-sm md:text-base"
            >
              Email or Mobile Number
            </label>
            <input
              type="text"
              id="contact"
              className="mt-2 px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400"
              placeholder="Enter your email or phone"
              onChange={(e) => setContact(e.target.value)}
              value={contact || ""}
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-700 font-semibold text-sm md:text-base">
              Products
            </label>
            <div className="mt-2 flex space-x-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="w-5 h-5 text-indigo-500 border-gray-300 rounded focus:ring-indigo-400"
                  name="products"
                  onChange={(e) => setProducts([...products, e.target.value])}
                  value="astra3d"
                />
                <span className="ml-2 text-gray-700">Astra3D</span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="w-5 h-5 text-indigo-500 border-gray-300 rounded focus:ring-indigo-400"
                  name="products"
                  onChange={(e) => setProducts([...products, e.target.value])}
                  value="propalty"
                />
                <span className="ml-2 text-gray-700">Propalty</span>
              </label>
            </div>
          </div>
          {/* Message */}
          <div className="flex flex-col">
            <label
              htmlFor="content"
              className="text-gray-700 font-semibold text-sm md:text-base"
            >
              Message
            </label>
            <textarea
              id="content"
              rows="4"
              className="mt-2 px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400"
              placeholder="Write your message"
              onChange={(e) => setMessage(e.target.value)}
              value={message || ""}
              required
            ></textarea>
          </div>
          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-indigo-500 to-sky-500 text-white py-3 rounded-lg shadow-lg font-bold hover:from-sky-500 hover:to-indigo-500 transition-colors duration-500 ease-in"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>
      {popUp.show && (
        <Popup
          message={popUp.message}
          type={popUp.type}
          onClose={() =>
            setPopUp({ show: false, message: "", type: "", timeOut: null })
          }
        />
      )}
    </div>
  );
};
