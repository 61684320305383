import React, { useState } from "react";
import Logo from "../assets/reeal.png";
import { About } from "./About";
import { FaTimes } from "react-icons/fa";
import { Products } from "./Products";
import { Contacts } from "./Contacts";

export const Underlay = () => {
  const [isOpenAbout, setIsOpenAbout] = useState(false);

  const openModalAbout = () => {
    setIsOpenAbout(true);
  };

  const closeModalAbout = () => {
    setIsOpenAbout(false);
  };

  const [isOpenProduct, setIsOpenProduct] = useState(false);

  const openModalProduct = () => {
    setIsOpenProduct(true);
  };

  const closeModalProduct = () => {
    setIsOpenProduct(false);
  };

  const [isOpenContact, setIsOpenContact] = useState(false);

  const openModalContact = () => {
    setIsOpenContact(true);
  };

  const closeModalContact = () => {
    setIsOpenContact(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: 40,
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        pointerEvents: "none",
      }}
    >
      <div
        className="relative px-[3%]"
        style={{
          pointerEvents: "all",
          pointer: "auto",
          width: "100%",
          padding: 0,
          left: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <img src={Logo} alt="/" width={150} height={150} />
        </div>

        <div style={{ flex: "1 1 0%", display: "flex", gap: "2em" }}></div>
        <div className="space-y-3">
          <div
            style={{
              flex: "1 1 0%",
              fontSize: 12,
              fontWeight: "700",
              lineHeight: "100%",
              textAlign: "right",
              color: "black",
              marginTop: 0,
            }}
          >
            <button onClick={openModalAbout}>ABOUT ● </button>
            {isOpenAbout && (
              <div
                className={`fixed ${
                  window.innerWidth > 900 ? "p-[30px]" : "p-5"
                } inset-0 z-50 flex items-center  bg-[#EAEDF6] bg-opacity-50`}
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    closeModalAbout();
                  }
                }}
              >
                <div
                  className={`absolute  ${
                    window.innerWidth > 900
                      ? "top-3 right-3 m-4"
                      : "top-2 right-2 m-2"
                  }`}
                >
                  <button onClick={closeModalAbout}>
                    <FaTimes size={30} />
                  </button>
                </div>
                <About />
              </div>
            )}
          </div>
          <div
            style={{
              flex: "1 1 0%",
              fontSize: 12,
              fontWeight: "700",
              lineHeight: "100%",
              textAlign: "right",
              color: "black",
            }}
          >
            <button onClick={openModalProduct}>PRODUCT ● </button>
            {isOpenProduct && (
              <div
                className={`fixed ${
                  window.innerWidth > 900 ? "p-[30px]" : "p-5"
                } inset-0 z-50 flex items-center  bg-[#EAEDF6] bg-opacity-50`}
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    closeModalProduct();
                  }
                }}
              >
                <div
                  className={`absolute  ${
                    window.innerWidth > 900
                      ? "top-3 right-3 m-4"
                      : "top-2 right-2 m-2"
                  }`}
                >
                  <button onClick={closeModalProduct}>
                    <FaTimes size={30} />
                  </button>
                </div>
                <Products />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            flex: "1 1 0%",
            height: "100%",
            fontSize: 12,
            lineHeight: "1.5em",
            color: "black",
          }}
        >
          <b>Crafting Beyond Reality</b>
          <br />
          <b>—</b>
        </p>
      </div>
      <div
        className="w-full mb-8"
        style={{
          fontFamily: "'Antonio', sans-serif",
          flex: "1 1 0%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <p
          className="hidden sm:block"
          style={{
            flex: "1 1 0%",
            fontSize: 200,
            lineHeight: "1em",
            color: "black",
            margin: 0,
            letterSpacing: -10,
          }}
        >
          XR
        </p>
        <div style={{ width: 10 }} />
        <p
          className="hidden sm:block"
          style={{
            flex: "1 1 0%",
            fontSize: 200,
            lineHeight: "100%",
            textAlign: "right",
            color: "black",
            margin: 0,
            letterSpacing: -10,
          }}
        >
          _VR
        </p>
      </div>

      <div
        style={{
          pointerEvents: "all",
          pointer: "auto",
          width: "100%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "start",
        }}
      >
        <p
          className="hidden sm:block"
          style={{
            flex: "1 1 0%",
            fontSize: 12,
            lineHeight: "1.5em",
            color: "black",
          }}
        >
          <b>A METAVERSE COMPANY</b>
          <br />
          Pioneering With Innovation
        </p>

        <p
          style={{
            fontFamily: "'Antonio', sans-serif",
            flex: "1 1 0%",
            fontSize: 16,
            fontWeight: "50",
            lineHeight: "1em",
            textAlign: "end",
            color: "black",
            letterSpacing: -0.5,
            whiteSpace: "nowrap",
          }}
        >
          <button onClick={openModalContact}> CONNECT TO KNOW MORE </button>
          {isOpenContact && (
            <div
              className={`fixed ${
                window.innerWidth > 900 ? "p-[30px]" : "p-5"
              } inset-0 z-50 flex items-center  bg-[#EAEDF6] bg-opacity-50`}
              onClick={(e) => {
                if (e.target === e.currentTarget) {
                  closeModalContact();
                }
              }}
            >
              <div
                className={`absolute  ${
                  window.innerWidth > 900
                    ? "top-3 right-3 m-4"
                    : "top-2 right-2 m-2"
                }`}
              >
                <button onClick={closeModalContact}>
                  <FaTimes size={30} />
                </button>
              </div>
              <Contacts />
            </div>
          )}
        </p>
      </div>
    </div>
  );
};
